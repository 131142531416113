.customer-analytics-container
{   background: #F8F9FA;
    display:flex;
    flex-direction:column;
    height:100vh;
}
.customer-analytics-content{
    margin: 100px 32px 32px 32px;
    height: 100vh;
    border: 1px solid #E6E9ED;
    border-radius: 5px;
    box-sizing: border-box;
    background: white;
    display:flex;
    flex-direction:column;
}
.pillButtonLeft,
.pillButtonLeft:focus,
.pillButtonLeft:active{
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    outline: none !important;
    box-shadow: none !important;
}
.pillButtonLeftInActive,
.pillButtonInActive,
.pillButtonRightInActive{
    background: #FFFFFF !important;
    color:#A7ABB0 !important;
    outline: none !important;
    box-shadow: none !important;
}
.pillButtonLeftActive,
.pillButtonActive,
.pillButtonRightActive{
    background: #F8F9FA  !important;
    color:#2E2E2E  !important;
    outline: none !important;
    box-shadow: none !important;
}
.pillButton,
.pillButton:active,
.pillButton:focus
{
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
    border-radius: 0px;
    outline: none !important;
    box-shadow: none !important;
}
.pillButton:hover,
.pillButtonLeft:hover,
.pillButtonRight:hover {
    outline: none;
    box-shadow: none;
    border: 1px solid #E6E9ED;
}
.pillButtonRight,
.pillButtonRight:active,
.pillButtonRight:focus{
    
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 0px;
    outline: none !important;
    box-shadow: none !important;
}
.graphTab,
.graphTab:active,
.graphTab:focus
{   background: white;
    border-radius: 8px;
    cursor: pointer;
}
.graphTabActive,
.graphTabActive:active,
.graphTabActive:focus
{   background: #F8F9FA;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    
}
.graphTabInActive,
.graphTabInActive:active,
.graphTabInActive:focus
{   background: white;
    cursor: pointer;
    border-radius: 8px;
}
.timeFrameGraphContainer{
    height: 76px;
    border-bottom: 1px solid #E6E9ED;
    display: flex;
    align-items: center
}
.timeLabelContainer{
    margin-left: 20px;
    margin-top: 10px;
    align-items: center;
    width:300px
}
.timeLabelContainer>h5{
    color: #2E2E2E
}
.timeFrameGraphButtons{
    margin-left: 20px;
    width: 500px
}
.chartFilterContainer{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction:column;
    padding: 30px
}
.chartFilterContainer>div{
width: 500px
}
.graphLine{ 
height: 20px;
border-bottom: 1px solid #E6E9ED
}
.userAnalyticsGraphActions{
    margin-top: '-10px';
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 10px;
     font-size: 16px;
     line-height: 23px
}
.userAnalyticsGraphDownloads{
    margin-top: '12px';
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 10px;
     font-size: 16px;
     line-height: 23px
}
.userAnalyticsLeftTab{
    display: flex;
     justify-content: space-between;
      height: 40px;
      align-items: center;
       padding: 10px
}

.userAnalyticsContent{
    display: flex;
     height: 500px;
      padding: 20px
}
.userAnalyticsContentInner{
    width: 30%;
    height:100%;
    border-right: 1px solid #E6E9ED
}
.userAnalyticsLeftLabel{
    margin-top: 20px;
    margin-right: 20px;
}
.userAnalyticsLeftLabel>h4{
    padding-left: 10px
}
.userAnalyticsLeftLabel>p{
    color: #A7ABB0;
    padding-left: 10px
}