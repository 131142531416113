.customer-analytics-containerDashboard
{   background: #F8F9FA;
    display:flex;
    flex-direction:column;
    height:100vh;
}
.customer-dashboard-titleDashboard{
    margin: 100px 32px 20px 52px;
}
.titleContainerDashboard{
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom: 1px solid #E6E9ED;
}
.titleContainerDashboard>h5{
 margin-top: 10px;
font-style: normal;
margin-left:20px;
color: #2E2E2E;
}
.customer-dashboard-titleDashboard>p
{
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 29px;
color: #2E2E2E;
}
.customer-analytics-contentDashboard{
    margin:0px 32px 32px 32px;
    height: 100vh;
    border: 1px solid #E6E9ED;
    border-radius: 5px;
    box-sizing: border-box;
    background: white;
    display:flex;
    flex-direction:column;
}
.pillButtonLeftDashboard,
.pillButtonLeftDashboard:focus,
.pillButtonLeftDashboard:active{
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    outline: none !important;
    box-shadow: none !important;
}
.pillButtonLeftInActiveDashboard,
.pillButtonInActiveDashboard,
.pillButtonRightInActiveDashboard{
    background: #FFFFFF !important;
    color:#A7ABB0 !important;
    outline: none !important;
    box-shadow: none !important;
}
.pillButtonLeftActiveDashboard,
.pillButtonActiveDashboard,
.pillButtonRightActiveDashboard{
    background: #F8F9FA  !important;
    color:#2E2E2E  !important;
    outline: none !important;
    box-shadow: none !important;
}
.pillButtonDashboard,
.pillButtonDashboard:active,
.pillButtonDashboard:focus
{
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
    border-radius: 0px;
    outline: none !important;
    box-shadow: none !important;
}
.pillButtonDashboard:hover,
.pillButtonLeftDashboard:hover,
.pillButtonRightDashboard:hover {
    outline: none;
    box-shadow: none;
    border: 1px solid #E6E9ED;
}
.pillButtonRightDashboard,
.pillButtonRightDashboard:active,
.pillButtonRightDashboard:focus{
    
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 0px;
    outline: none !important;
    box-shadow: none !important;
}
.graphTabDashboard,
.graphTabDashboard:active,
.graphTabDashboard:focus
{   background: white;
    border-radius: 8px;
    cursor: pointer;
}
.graphTabActiveDashboard,
.graphTabActiveDashboard:active,
.graphTabActiveDashboard:focus
{   background: #F8F9FA;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    
}
.graphTabInActiveDashboard,
.graphTabInActiveDashboard:active,
.graphTabInActiveDashboard:focus
{   background: white;
    cursor: pointer;
    border-radius: 8px;
}
.timeFrameGraphContainerDashboard{
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.timeLabelContainerDashboard{
    margin-left: 20px;
    margin-top: 10px;
    align-items: center;
    
}
.timeLabelContainerDashboard>h5{
    color: #2E2E2E
}
.timeFrameGraphButtonsDashboard{
    margin-left: 20px;
    width: 420px
}
.chartFilterContainerDashboard{
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction:column;
    padding: 20px 30px 30px 30px
}
.chartFilterContainerDashboard>div{
width: 500px;
}
.graphLineDashboard{ 
height: 20px;
border-bottom: 1px solid #E6E9ED
}
.userAnalyticsGraphActionsDashboard{
    margin-top: '-10px';
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 10px;
     font-size: 16px;
     line-height: 23px
}
.dashboardLine{
border-right: 1px solid #E6E9ED
}
.userAnalyticsGraphDownloadsDashboard{
    margin-top: '12px';
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 10px;
     font-size: 16px;
     line-height: 23px
}
.userAnalyticsLeftTabDashboard{
    display: flex;
     justify-content: space-between;
      height: 40px;
      align-items: center;
       padding: 10px
}

.userAnalyticsContentDashboard{
    display: flex;
     height: 500px;
     
}
.userAnalyticsContentInnerDashboard{
    width: 20%;
    height:100%;
    /* border-right: 1px solid #E6E9ED */
}
.userAnalyticsLeftLabelDashboard{
    margin-top: 20px;
    margin-right: 20px;
    padding: 0px 10px 20px 20px;
}
.userAnalyticsLeftLabelDashboard>h4,.userAnalyticsRightLabelDashboard>h5{
    padding-left: 10px
}
.userAnalyticsLeftLabelDashboard>p,.userAnalyticsRightLabelDashboard>p{
    color: #A7ABB0;
    padding-left: 10px
}
.userAnalyticsRightLabelDashboard{
    /* margin: 20px; */
    width:30%;
    max-height:600px;
}
.usersTabTopContainer{
    display:flex;
    flex-direction:column
}
.lineDashboard{
    height: 20px;
border-bottom: 1px solid #E6E9ED
}