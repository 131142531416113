.add-sales-container{
    display:flex;
    flex-direction:column;
    align-items:center
}
.add-sales-container-form{
    width:40%;
    margin-top:150px;
    justify-self:center
}
.add-sales-submit-button{
margin-left:50px;
margin-top:20px;
}
.form-fields{
margin-top:50px;
}
.add-sales-title{
margin-left:70px;
}
.sales-form-error{
    color:#FF0000;
    font-size: 15px;
}