.users-content{
  display:flex;
  flex-direction:column;
  margin-top:50px;
  margin-top:60px;
  min-height:500px;
  border-top: 1px solid #E8E8E8;
  max-width:100%;
  word-break: 'break-word'
}
.add-user-container{
  position: -webkit-sticky;
  position:fixed;
  top: 4rem;
  width:100%;
  background-color: white;
}
.add-user{
  height:80px;
  margin-left:50px;
  margin-right: 50px;
}
.add-user-button{
 float:right;
 margin-top: -40px;
}
.users-date-filters{
  position: -webkit-sticky;
  position:fixed;
  top: 9rem;
  height:104px;
  width:100%;
  background-color:white;
  display:flex;
  align-items:center;
  z-index:3;
}
.users-date-filter-inner{
  display:flex;
  justify-content: space-between;
  height:100%;
  min-width:100%;
  border-top: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  align-items:center;
  padding-right: 64px;
}
.usets-date-filter-title{
  margin-left:50px;
  margin-top:10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  justify-self:center;
  min-width: 150px;
}
.autosuggest-suggestions-container-add-user {

  /* border-radius: 4px; */
  background-color: white;
  z-index: 5;
  position: absolute;


}

.autosuggest-suggestions-container-add-user > ul{
  border: 1px solid #E6E9ED;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0px;
  list-style: none;
  padding-left: 0;
  max-height: 268px;
  overflow: auto;
}

.suggestion-item-add-user {
  cursor: pointer;
  padding: 8px 8px;
  height: auto;
  margin: 8px 0px;
  z-index:5

}

.suggestion-item-add-user:hover{
  background-color: rgba(41, 122, 247, 0.05);
}

.ip-add-user-email{    
  box-sizing: border-box;
  height: 42px;
  padding: 16px;
  margin-top: 4px;
  margin-bottom: 12px;
  border-radius: 8px;
  outline: none;
  border: none;
  width:600px;
}

.autosuggest-suggestions-container > ul > li > div{
  line-height: 20px;
}
.user-filter-container{
      position: -webkit-sticky;
      position:fixed;
      top: 10.5rem;
      height:65px;
      width:100%;
      background-color:white;
      display:flex;
      align-items:center;
      z-index:1;
      padding-top:20px
}







.autosuggest-suggestions-container-detailed-analytics {

  /* border-radius: 4px; */
  background-color: white;
  z-index: 5;
  position: absolute;


}

.autosuggest-suggestions-container-detailed-analytics > ul{
  border: 1px solid #E6E9ED;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0px;
  list-style: none;
  padding-left: 0;
  max-height: 268px;
  overflow: auto;
}

.suggestion-item-detailed-analytics {
  cursor: pointer;
  padding: 8px 8px;
  height: auto;
  margin: 8px 0px;
  z-index:5

}

.suggestion-item-detailed-analytics:hover{
  background-color: rgba(41, 122, 247, 0.05);
}

.adv-filter-ip-detailed-analytics-user{    
  box-sizing: border-box;
  height: 42px;
  padding: 16px;
  background: #F5F6F7;
  margin-top: 4px;
  margin-bottom: 12px;
  border-radius: 8px;
  outline: none;
  border: none;
  width:200px;
}
.adv-filter-ip-detailed-analytics-email{    
  box-sizing: border-box;
  height: 42px;
  padding: 16px;
  background: #F5F6F7;
  margin-top: 4px;
  margin-bottom: 12px;
  border-radius: 8px;
  outline: none;
  border: none;
  width:300px;
}
.autosuggest-suggestions-container > ul > li > div{
  line-height: 20px;
}
.user-filter-container{
      position: -webkit-sticky;
      position:fixed;
      top: 10.5rem;
      height:65px;
      width:100%;
      background-color:white;
      display:flex;
      align-items:center;
      z-index:1;
      padding-top:20px
}

.thead-organization-users{
  top: 15rem;
}

.thead-organization-users>th{
  top: 20rem
}

.organization-content{
  margin:50px;
  margin-top: 120px;
  min-height:500px;
  z-index:-3 inherit;
}
.organization-content-container{
  margin-top:180px;
}