.section-root{
    padding: 20px 16px 16px 16px;
    border-radius: 8px;
    /* border: 1px solid #E6E9ED;
    background-color: #F9FAFC; */
    width: 100%;
    align-self: flex-start;
    margin: 16px 0px;
    /* max-height: 500px;
    overflow: auto; */
}

.section-title{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2E2E2E;
}
.section-root > p {
    margin-bottom: 8px;
}