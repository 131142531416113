.blankslate-root {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ErrorMessage404{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;

}