body{
    margin: 0;
    color: #2E2E2E;
    font-family: 'Inter';
    
}

.paragraph-secondary{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
}

button:disabled{
    opacity: 0.5;
}


.nxt-btn {
  width: 100%;
  height: 42px;
  background-color: #297AF7;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border: none;
  outline: none;
  border-radius: 8px;
}

.nxt-btn:hover{
  background-color: #297AF7;
  color: white;
}

hr {
  margin-left: -32px;
  margin-right: -32px;
}
