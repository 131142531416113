
.tbl-root{
    width: 80%;
    /* border: 1px solid #E6E9ED; */
    margin-top: 24px;
}

.tbl-root > thead {
    background-color: white;
    font-style: normal;

    font-size: 12px;
    line-height: 15px;
    color: #2E2E2E;
    /* border-bottom: 1px solid #E6E9ED; */
}

.tbl-root > thead > tr > th, .tbl-root > tbody > tr > td {
    font-weight: normal;
    padding: 8px 0px;
}


/* .tbl-root > tbody > tr:nth-child(odd){
    background-color: #F9FAFC;
} */
.tbl-root > tbody > tr:nth-child(even){
    background-color: white;
}


.tbl-actions {
    display: flex;
}

.tbl-actions > div {
    width: 100px;
    height: 42px;
    text-align: center;
    background: #F5F6F7;
    /* border-top: 1px solid #CDD1D7;
    border-bottom: 1px solid #CDD1D7; */
    
    font-size: 12px;
    line-height: 15px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tbl-actions > div:hover{
    background-color: rgba(41, 122, 247, 0.05);
}

.table-action-active{
    background-color: rgba(41, 122, 247, 0.05) !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #297AF7;


}

.tbl-actions > div:first-child {
    border-radius: 7px 0px 0px 7px;
    /* border: 1px solid #CDD1D7; */
}
.tbl-actions > div:last-child {
    border-radius: 0px 7px 7px 0px;
    /* border: 1px solid #CDD1D7; */
}

.trash-icon-container{
    padding: 8px;
    border-radius: 8px;
    width: 42px;
    cursor: pointer;
}

.add-more-btn{
    background: #FFFFFF;
    border: 1px solid #297AF7;
    box-sizing: border-box;
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    font-weight: 600;
    color: #297AF7;
}

.add-more-btn:hover{
    /* background-color: #297AF7; */
    /* color: white; */
    border: 1px solid #216BDE;
    color: #216BDE;
}
.seeMoreCriteriaCaret{
    margin-left: 16px;
}
.SeeMoreCriteriaButton{
    display: flex;
    align-items: center;
    padding: 2px;
    border: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    margin-left: -2px;
    margin-bottom: 8px;
    outline: none;
}
.SeeMoreCriteriaButton:hover{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    background: none;
    border:none;
}
.SeeMoreCriteriaButton:active{
    color: #2E2E2E;
    background-color: none;
    outline: none;
}
.SeeMoreCriteriaButton:focus{
    outline: none;
    box-shadow: none;
}