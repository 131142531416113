.heading{
    font-style: inter;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: #2E2E2E;
}
.headerContainer{
    background: #F8F9FA;
    height: 100%;
    width: 100%;
    padding: 74px 0px 0px 0px;
}
.newFlowHeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F8F9FA;
    height: 100%;
    width: 100%;
    padding: 36px 64px;
    border-bottom: 1px solid #E6E9ED;
}
.newFlowHeading{
    text-transform: capitalize;
}
.mainTitleHeadingContainer{
    margin-left: 20%;
    margin-right: auto;
}
/* p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    
    color: #2E2E2E;
} */
.sectionHeading{
    padding-left: 32px;
    padding-bottom: 28px;
    padding-top: 28px;
    display: flex;
}
.sectionHeading > h2{
    
    font-style: normal;
    font-weight: bold;
    margin: 0px;
    font-size: 18px;
    color: #2E2E2E;
}
.dailyLimitConatiner{
    align-items: end;
}
.dailyLimitConatiner > p{
    margin-bottom: 0px;
}
.tabsContainer{
    height: 46px;
    background-color: #F8F9FA;
}
.stopCursor:hover{
    cursor: not-allowed !important;
}
.firstTab{
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    border-color: transparent transparent #E6E9ED transparent !important;
}
.secondTab{
    clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 1% 100%, 7% 50%, 1% 0%);
    margin-left: -14px;
    border-color: transparent transparent #E6E9ED transparent !important
}
.thirdTab{
    clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 1% 100%, 7% 50%, 1% 0%);
    margin-left: -14px;
    border-color: transparent transparent #E6E9ED transparent !important

}
.tabFilterPrimary{

}
.tabFilterSecondary{
    margin: 0px 0px 0px auto;
}
.tabLinkCounter{

}
.nav-link.active{
    background-color: #FFFFFF !important;
    border-color: #FFFFFF!important;
    font-weight: 600 !important;
}
.secondaryActive{
    border-color: #dee2e6 #dee2e6 #fff !important;
    background-color: #FFFFFF !important;
    font-weight: 600 !important;
}
.tabPrimaryLink{
    padding: 8px 24px 8px 16px;
    width: 230px;
    height: 100%;
    color: #495057;
    background-color: #F8F9FA;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    border-radius: 0px;
    border-right: 0px;
}
.tabPrimaryLink:hover{
    cursor: pointer;
}
.tabSecondaryLink{
    padding: 8px 16px;
    height: 100%;
    color: #495057;
    border-bottom: 1px solid transparent !important;
}
.tabSecondaryLink:hover{
    cursor: pointer;
    /* border-color: #FFFFFF #FFFFFF #E6E9ED #FFFFFF  !important; */
}
.archBorderUpper{
    border-left: 1px solid #dee2e6;
    transform: skew(30deg, 0deg);
    height: 23px;
    position: absolute;
    margin-left: 224px;
    margin-top: -46px;
}
.archBorderLower{
    border-left: 1px solid #dee2e6;
    transform: skew(-30deg, 0deg);
    height: 23px;
    position: absolute;
    margin-left: 224px;
    margin-top: -23px;
}
.archBorderUpper2nd{
    border-left: 1px solid #dee2e6;
    transform: skew(30deg, 0deg);
    height: 23px;
    position: absolute;
    margin-left: 210px;
    margin-top: -46px;
}
.archBorderLower2nd{
    border-left: 1px solid #dee2e6;
    transform: skew(-30deg, 0deg);
    height: 23px;
    position: absolute;
    margin-left: 210px;
    margin-top: -23px;
}
.archBorderUpper3rd{
    border-left: 1px solid #dee2e6;
    transform: skew(30deg, 0deg);
    height: 23px;
    position: absolute;
    margin-left: 210px;
    margin-top: -46px;
}
.archBorderLower3rd{
    border-left: 1px solid #dee2e6;
    transform: skew(-30deg, 0deg);
    height: 23px;
    position: absolute;
    margin-left: 210px;
    margin-top: -23px;
}
.sectionFilter{
    padding-top: 8px;
    margin: 0px 16px;
    align-items: center;
    top: 0;
    z-index: 1;
    background: #F8F9FA;
    border: 1px solid #E6E9ED; 
    border-radius: 8px;

}
/* .sectionFilter > button{

    margin-left: 5px;
    margin-right: 5px;
    padding: 10.5px 16px;
    border-radius: 8px;

} */

.sectionlist > ul > li {
    display: flex;
}
.trashIcon{

}
.trashIconContainer, .trashIconContainer:focus, .trashIconContainer:active{
    padding: 6px 8px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #F5F6F7 !important;
    margin: 0px 8px;
}
.trashIconContainer:hover{
    border: none;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);

}
.minus-icon-container{
    margin: 2px;
    margin-left: 24px;
    padding: 8px;
    cursor: pointer;
    border: 0px solid;
}
/* #filterButton{
    display: flex;
    float: right;
    margin-right: 36px;
    font-size: 12px;
    line-height: 15px;
} */
.filterIconButtons, .filterIconButtons:focus, .filterIconButtons:active {
    padding: 6px 8px;
    border: none;
    outline: none;
    background-color: #EEF3FB !important;
    color: #007bff !important;
    border-radius: 8px;
    margin: 0px 8px;
}
.filterIconButtons:hover{
    border: none;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
}
.filterIconSVG{

}

.bookmarkProspectIconButton, .bookmarkProspectIconButton:focus, .bookmarkProspectIconButton:active{ 
    display: flex;
    float: right;
    padding: 0px;
    border: none;
    outline: none;
    background-color: transparent !important;
    box-shadow: none;
    border-radius: 8px;
    margin: 0px;
    margin-left: auto;
}
.bookmarkProspectIconButton:hover{
    border: none;
    outline: none;
    background: none;
}
.checkbox{
    position: relative;
    margin: 0px;
    width: 24px;
    height: 24px;
    background: #297AF7;
    border: 1px solid #297AF7;
    box-sizing: border-box;
    border-radius: 6px;
    margin-left: 0px;
}
.indexContainer{
    margin-top: 50px;
    margin-left: -8px;
    position: absolute;
    display: flex;
    text-align: center;
    width: 40px;
    justify-content: center;
    align-items: center;
}
.scorecard-container{
    margin-left: 16px;
    margin-bottom: 0px;
    margin-top: 32px;
    margin-right: 16px;
    width: 100%;
    border-radius: 8px;
}
.scoreContainer{
    display: flex;
    margin: 0px;
    padding: 0px;
    border-radius: 8px;
}
.cardHeader{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    padding-top: 21px;
    padding-bottom: 21px;
    padding-left: 16px;
    color: #2E2E2E;
    background-color: #F8F9FA;
}
.progressContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.progress{
    margin: 0px;   
    margin-top: 8px;
    margin-bottom: 8px; 
    border: 0;
    height: 8px;
    width: 62%;
    border-radius: 4px;
}
.col{
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
}
.card-body{
    padding: 0px;
}
.sidebar{
    padding: 16px;
    padding-top: 20px;
    border-right: 1px solid #E6E9ED;
    background-color: #F8F9FA;
    width: 25%;
    border-radius: 8px 0px 0px 8px;
}
.displayProspectContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
}
.displayNameContainer{
    margin-bottom: 8px;
}
#detailsContainer{
    background-color: #F8F9FA;
    border-bottom: none;
    padding: 16px;
    border-radius: 0px 0px 8px 0px;
    border-radius: 0px 0px 8px 0px;
}
.moreDetailHead > Button > span{

}
.moreDetailHead > Button{
    border: 1px solid #CDD1D7;
    border-radius: 8px;
    padding: 0px !important;
    margin-top: 0px !important;
    margin-right: 0px!important;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.progressTitles {
    margin: 0px;
    font-size: 16px;
    min-width: 40%;
}
.scoreTable{
    width:75%;
    padding-top: 12px;
    border-radius: 0px 8px 0px 0px;
}
.boldAttributes{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #2E2E2E;
    margin: 0px;
}
.RelevantExpQualificationTagUnder{
    display: inline;
    margin: 0px 8px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    color: #EF5555;
    background: rgba(239, 85, 85, 0.08);
}
.RelevantExpQualificationTagOver{
    display: inline;
    margin: 0px 8px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    color: #EF5555;
    background: rgba(239, 85, 85, 0.08);
    /* color: #63B780;
    background: rgba(99, 183, 128, 0.12); */
}
.odd, .even{
    margin-right: 0px;
    margin-left: 0px;
    /* padding-bottom: 12px; */
    border-bottom: 1px solid #E6E9ED;
}
.connectionDegree{
    margin-left: 8px;
    display: inline;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 0px;
    color: #A7ABB0;
}
.seeMoreCaret{
    margin-left: 8px;
}
.greyed{
    display:inline;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #A7ABB0;
}
.info{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    margin-bottom: 4px;
}
.locationInfo{
    /* margin-top: 8px */
}
.attributes{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.values{
    padding: 20px 24px 16px 24px;
}
.valuesVisa{
    padding: 20px 24px 16px 24px;
    max-width: 20%;
}
.skillvalues{
    padding-left: 0px;
    padding-right: 0px;
    margin: 12px 0px 0px 0px;
}
.skillvalues > button > img{
    margin-left: 4px;
}
.flag{
    margin-left: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    background: rgba(239, 85, 85, 0.08);
    border-radius: 8px;
    padding: 4px;
    color: #EF5555;
    margin-bottom: 10px;
}
.expContainer{
  display: flex;
}
.exp1{
    min-width: 20%;
    max-width: 20%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
}
.exp2{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    margin-bottom: 8px;
    margin-left: 0px;
}
.timeSpan{
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #A7ABB0;
}
.timeSpan:hover{
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #A7ABB0;
}
.skillTag{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    background: #F5F6F7;
    border: 0px solid;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4.5px 8px 4.5px 8px;
}
.missingTag{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #EF5555;
    background: rgba(239, 85, 85, 0.08);    
    border: 0px solid;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4.5px 8px 4.5px 8px;
}
.expCol1{
    padding: 0px;
    padding-left: 12px;}
.expCol2{
    padding: 0px;
}
.toggler{
    display: flex;
    align-items: center;
    padding: 2px;
    border: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    margin-left: -2px;
    margin-bottom: 8px;
    outline: none;

}
.toggler > div {
    display: flex;
    align-items: center;
}
.toggler:hover{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    background: none;
}
.toggler:active{
    color: #2E2E2E;
    background-color: none;
    outline: none;
}
.toggler:focus{
    outline: none;
    box-shadow: none;
}
.details{
    font-style: normal;
    font-size: 16px;
    line-height: 15px;
    border: 0px;
    color: #A7ABB0;
    margin: 0px;
    outline: none;
    padding: 8px;
}
.details:hover{
    color: #A7ABB0;
    border: none;
    background: none;
}
.details:active{
    color: #A7ABB0;
    border: none;
    background-color: none;
    outline: none;
}
.details:focus{
    outline: none;
    border: none;
    box-shadow: none;
}
.dropcardbody{
    background: none;
    border: none;
    display: flex;
    flex-wrap: wrap;
}
.dropcard{
    background: none;
    border: none;
}
.bar{
    margin: 0px 0px 8px;
    border: 0;
    border-top: 1px solid #E6E9ED;
}
.filterbar{
    margin-top: 12px;
    margin-bottom: 0px;
    border: 0;
    border-top: 1px solid #E6E9ED;
}
.collapsible{
    margin: 0px 30px 8px 78px;
}
.dropDownButtons{
    padding: 2px 8px;
    border: 0px;
    margin: 4px 4px;
    border: 1px solid #297AF7;
    box-sizing: border-box;
    border-radius: 6px;
    background: #EEF3FB;
    display: flex;
}
.dropDownButtons:hover{
    color: #297AF7;
    border: 1px solid #297AF7;
    background: white;
}
.dropDownButtons:active{
    color: #297AF7;
    background-color: white !important;
    outline: none;
}
.dropDownButtons:focus{
    outline: none;
    box-shadow: none;
}
.dropdowntext{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #297AF7;
    /* background: #EEF3FB; */
    margin: 0px;
}
.sortingDropDownDefaultTag{
    display: inline;
    margin-left: 4px;
    color: #A7ABB0;
}
.DropDownDeviderTag:hover, .DropDownDeviderTag:focus, .DropDownDeviderTag:active {
    background: none;
    border:none;
}
.ddmenu{
    min-width: 6rem;
}
.dditem, .dditem:focus, .dditem:active{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 8px;
    min-width: 100%;
}
.dditem:hover{
    color: #2E2E2E;
    background: #f8f9fa;
    border:none;
}
.ddcaret{
    margin-left: 8px;
    margin-right: 0px;
    margin-top: 1px;
}
.dropdownContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.rightContainer{
    display: flex;
}
.leftContainer{
    
    display: flex;
    align-items: center;
}
.filterClear{
    margin: 0px 16px;
}   
.filterClear:hover{
    cursor: pointer; 
}
.advancedFilters{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding: 4px 8px;
    line-height: 15px;
    /* color: #297AF7; */
    margin-top: 0px;
    margin-left: 6px;
}
.advancedFilters:hover{
    cursor: pointer;
}
.clearFilters{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #297AF7;
    margin-top: 0px;
}
.clearFilters:hover{
    cursor: pointer;
}
.advancedFilterSection{
    /* margin: 24px 32px 12px 32px; */
    max-width: 70%;
}
.filterCancelButton{
    margin: 0px 16px;
}
.filterApplyButton{

}
.FilterSortButton{
    color: inherit;
    display: inline;
    padding: 0px;
    margin-left: 8px;
    font-size: 24px;
    line-height: 13px;
    margin-bottom: 0px;
}
.filterNavHeading{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2E2E2E;
    margin-left: 10px;
    margin-bottom: 0px;
}
.filterNavHeader{
    background: #F8F9FA;
    border-bottom: 1px solid #E6E9ED;
    box-sizing: border-box;
    border-radius: 8px;
}
.filterNav{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.leftFilterNav{
    display: flex;
    align-items: center;
}
.leftFilterNav > button > img{
    width: 9px;
    display: flex;
}
.leftFilterNav > button{
    padding: 10px 14px;
    border: 1px solid #A7ABB0;
    border-radius: 8px;
}
.rightFilterNav{
    display: flex;
    align-items: center;

}
.filterCancelButton, .filterApplyButton  {
    border-radius: 8px;
}
.rightFilterNav > span, button{
    /* margin-left: 12px; */
}
.topFilterBody{
    display: flex;
}
.rightFilterBody{
    display: flex;
    border-bottom: 1px solid #E6E9ED;
    width: 100%;
    justify-content: center;
    padding: 16px;
    align-items: center;
}.leftFilterBody{
    display: flex;
    border-bottom: 1px solid #E6E9ED;
    border-right: 1px solid #E6E9ED;
    width: 100%;
    justify-content: center;
    padding: 16px;
    align-items: center;
}
.rightFilterBody > label > input, .leftFilterBody > label > input{
    transform: scale(1.8);
    margin: 4px;
    display: flex;
}
.rightFilterBody > label > h3, .leftFilterBody > label > h3{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
    margin-bottom: 0px;
    margin-left: 30px;
    display: flex;
}
.filterSubHeading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
    margin-bottom: 8px;
}
.bottomFilterBody{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
}

.suggestionMainSpanContainer{
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin: 16px;
}
.searchArea{
    background: #F5F6F7;
    border-radius: 8px;
    border: 0px;
    width: 320px;
    height: 42px;
    margin-top: 4px;
    margin-bottom: 12px;
}
.suggestionLabel{
    width: 280px;
    margin-left: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #2E2E2E;
}
.form-group{
    margin-bottom: 0px;
}
.suggestionCheckBoxContainer{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    /* height: 128px;
    overflow: hidden; */
}
.suggestionCheckBoxContainer:hover{
    overflow: auto;
}

::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
    background: #F5F6F7;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #A7ABB0;
}

.visaContainer{
    display: flex;
    margin-bottom: 8px;
}
.visaLabel{
    margin-left: 8px;
}
.sectionlist > ul > li > .checkbox{
    width: 24px;
    height: 24px;
    margin-top: 8%;
    margin-left: 0px;
}
.sectionlist > ul{
    margin-bottom: 0px;
    padding-left: 50px;
    list-style: none;
}
.notAvailable{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #A7ABB0;
    min-width: 110px;
    margin-bottom: 8px;
    display: inline;
    text-decoration: none;
}
.notAvailable:hover{
    text-decoration: none;
    color: #A7ABB0;
    cursor: text;
}
.exp > a{
    font-size: 12px;
}
.loader{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: -200px 0px;
}
body{
    font-family: inter;
    background: #FFFFFF;
}
.Info{
    margin-right: 0px;
}
.notSpecified{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #A7ABB0;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: auto;
}
.linkedIcon{
    margin-left: 8px;
}
.displayName{
    display: inline;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}
.displayPicture{
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin-bottom: 24px;
}
.enhanceDisplayPicture{
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin: 0px;
    margin-right: 8px;
}
#expValues{
    /* flex-direction: column; */
}
.seeMoreButton{ 
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #A7ABB0;
    background: #FFFFFF;
    border: 0px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4.5px 8px 4.5px 8px;
}
.seeMoreButton:focus, .seeMoreButton:active, .seeMoreButton:hover{
    border: none;
    color: #A7ABB0 !important;
    background-color: #FFFFFF !important;
}
.missingInfo{
    display: flex;
    color:#EF5555;
    padding : 0px;
    margin : 0px 4px;

}
.boldInfo{
    display: flex;
    padding : 0px;
    margin : 0px 4px;
    font-weight: bold;
}
.verBar{
    border-right: 1px solid #E6E9ED;
    padding: 0px;
    margin: 0px 8px;
    height: 16px;
}
/* 
.dropdown-item {
    width: unset;
} */
/* 
.dropdown-item:hover {
    width: unset;
} */
.selectAllCheckBox{
    display: inline;
    position: relative;
    width: 24px;
    height: 24px;
    margin: 0px;
    top: 8px;
}
.selectionPopContainer{
    display: inline;
}
.selectionPop{
    margin: 0px;
    border: none;
    background: none;
    padding: 0px 8px;
}
.selectionPop:hover{
    border: none;
    background: none;
    outline: none;
}
.selectionPopMenu{
    min-width: 273px;
    min-height: 155px;
    padding: 8px 0px;
}
.selectionPopItem,.selectionPopItem:focus,.selectionPopItem:active{
    border: none;
    outline: none;
    border-radius: 0px;
    padding: 8px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
}
.selectionPopItem:hover{
    border: none;
    outline:none;
}
.selectionApply{
    border:none;
    outline:none;
}
.selectionApply:hover{
    border:none;
    outline:none;
}
.selectionApply:focus{
    border:none;
    outline:none;
}
.topSelection{
    width: 69px;
    margin: 0px;
}
.topSelectionContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.applyContainer{
    display: flex;
    justify-content: flex-end;
    margin: 8px 0px 0px 0px;
}
.downloadButtonCSV{
    
    margin-left: 5px;
    margin-right: 5px;
    padding: 10.5px 16px;
    border-radius: 8px;
}
.sacMainContainer{
    
}
.main-sac-body-container{
    margin: 0px;
    display: flex;

}
.sac-container{
    margin: 0px 0px;
    padding: 0px;
}
.leftNavContainer{
    padding: 0px;
    margin: 0px;
    width: 20%;
    min-height: 100vh;
    background: #F8F9FA;
    border-right: 1px solid #E6E9ED;
}
.leftNavSection{
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 0px;
}
.leftNavHeaderContainer{
    padding: 16px;
}
.leftNavHeader{
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #A7ABB0;
}
.leftNavBodyContainer{

}
.leftNavBodyMain{

}
.leftNavBodyMain > ul{
    margin: 0px;
    padding: 0px;
}
.leftNavBodyMain > ul > li{
    margin: 0px;
    padding: 0px;
}
.firstNavListDeco{
    position: absolute;
    left: -9px;
    top: -3px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #CDD1D7;
}
.firstNavListDecoConstainer{
    position: absolute;
    width: 16px;
    top: 66px;
    left: 28px;
}
.secondNavListDeco{
    position: absolute;
    z-index: 4;
    left: 28px;
    top: -3px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #CDD1D7;

}
.secondNavListDecoConstainer{
    position: absolute;
    z-index: 2;
    width: 32px;
    top: 88px;
    left: 7px;
    border-bottom: 1px solid #E6E9ED;
    transform: rotate(90deg);
}
.thirdNavListDeco{
    position: absolute;
    left: -9px;
    top: -3px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #CDD1D7;

}
.thirdNavListDecoConstainer{
    position: absolute;
    width: 16px;
    top: 142px;
    left: 29px;
}
.leftNavBodyItem{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-left: 4px solid transparent;
}
.navItemActive{
    border-left-color: #297AF7;
}
.leftNavBodyItem:hover {
    background: #EEF3FB;
    cursor: pointer;
}
.leftNavBodyMainTitleContainer{
    display: flex;
    align-items: center;
}
.leftNavBodySecondaryTitleContainer{
    margin-left: 20px;
}
.leftNavBodySecondaryTitle{
    margin: 0px;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #2E2E2E;
}
.leftNavBodyMainTitle{
    margin: 0px;
    margin-left: 20px;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #2E2E2E;
}
.navTitleActive{
    color: #297AF7;
    font-weight: 600;
}
.navBodyCounterContainer{

}
.navBodyCounter{
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    
    color: #A7ABB0;
}
.navCounterActive{
    color: #2E2E2E;
    font-weight: 600;
}
.navHorizontalDevider{
    margin: 8px 16px;
}
.leftNavBodySecondary{

}
.leftNavBodySecondary > ul{
    margin: 0px;
    padding: 0px;
}
.leftNavBodySecondary > ul >li{
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
}
.heading {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 8px;
    /* padding-left: 20%; */
}

.subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 40px;
    /* padding-left: 20%; */
}

.sac-content {
    background-color: white;
    margin-bottom: 32px;
}
.jd-steps{
    border-bottom: none;
}
.bottomBorder{
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.moreDetailContainer{
    margin-top: 78px;
    max-width: 45%;
}
.moreDetailHead{
    background: #F8F9FA;
}
.moreDetailHeading{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2E2E2E;
    margin: 8px 0px;
}
.moreDetailSubHeading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
    margin-bottom: 16px;
}
.moreDetailBody{
    background: #F8F9FA;
    padding: 16px 24px;
}
.detailSection{

}
.detailRequire, .detailFound{
    min-width: 0px;
}
.detailRequire{
}
.detailFound{
    margin-top: 38px;
}
.requiredDetails{
    margin: 6px 0px;
}
.requiredSumDetails{
    margin-top: 11px;
}
.foundSumDetails{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
    display: inline;
}
.foundDetails{
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
    margin: 6px 0px;
}
.detailTagHeading{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #A7ABB0;
    margin-bottom: 8px;
}
.foundSumDetailsContainer{
    margin-top: 18px
}
.detailSkillContainer{
    
    display: flex;
    flex-wrap: wrap;
}
.detailSkill{
    background: #E6E9ED;
    border-radius: 8px;
    display: inline;
    margin: 0px 8px 8px 0px;
    padding: 4px 8px;
}
.selectionCounterTag{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #A7ABB0;
    display: inline;
}
.selectionCounterTagBold{
    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: 100px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #A7ABB0;
}

.tt-content{
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    width: 330px;
}

.tooltip-root{
    width: 330px;
}

.tt-content > div {
    width: 55px
}

.tt-content > p {
    color: white;
    /* width: 85%; */
    flex-grow: 1;
    font-size: 12px;
    
}

.sent-icon {
    background-color: #63B780;
    mask-image: url('../../../../img/check-green.svg');
    -webkit-mask-image: url('../../../../img/check-green.svg');
    width: 24px;
    height: 24px;

}

.external-link-icon {
    background-color: #A7ABB0;
    mask-image: url('../../../../img/external-link-small.svg');
    -webkit-mask-image: url('../../../../img/external-link-small.svg');
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.info-icon {
    background-color: #A7ABB0;
    mask-image: url('../../../../img/info.svg');
    -webkit-mask-image: url('../../../../img/info.svg');
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.info-icon-red {
    background-color: #EF5555;
    mask-image: url('../../../../img/info.svg');
    -webkit-mask-image: url('../../../../img/info.svg');
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.limit-selector {
        width: 55px;
        background-color: white;
        border-radius: 4px;
        margin: 0 10px;
}
.autosuggest-suggestions-container-activity {

    /* border-radius: 4px; */
    background-color: white;
    width:500px;
    z-index: 2;
    position: absolute;


}

.autosuggest-suggestions-container-activity > ul{
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
  
    margin-top: 0;
    margin-bottom:0px;
    list-style: none;
    padding-left: 0;
    max-height: 500px;
    overflow: auto;
    width:500px
}

.suggestion-item-activity {
    cursor: pointer;
    padding: 8px 8px;
    height: auto;
    margin: 8px 0px;
    width:500px;
    word-break: 'break-word'
}

.suggestion-item-activity:hover{
    background-color: rgba(41, 122, 247, 0.05);
}

.adv-filter-ip-activity{    
    box-sizing: border-box;
    width: 500px;
    height: 42px;
    padding: 16px;
    background: #f2f2f2;
    margin-top: 4px;
    margin-bottom: 12px;
    border-radius: 8px;
    outline: none;
    border: none;
    
}
.autosuggest-suggestions-container > ul > li > div{
    line-height: 20px;
}
.triStateCheckDiv{
    display: inline;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 32px;
    bottom: 45px;
    background: #A7ABB0;
    border-radius: 2px;
}
.triStateCheckSpan{
    display: block;
    margin: auto;
    margin-top: 10px;
    width: 12px;
    height: 4px;
    border-radius: 4px;
    background: #FFFFFF;
}
.blockedScreen{
    filter: blur(3px);
}
.blockedContainer{
}
.CoverBlocked{
    z-index: 99;
    position: absolute;
    width: 100%;
    top: 62px;
    height: 102%;
    border: 1px solid;
    background: black;
    opacity: 0.5;
}
.BlockPopUp{
    z-index: 999;
    position: absolute;
    width: 375px;
    height: 164px;
    left: 50%;
    top: 40%;
    transform: translateX(-50%) translateY(-100%);
    background: #FFFFFF;
    border-radius: 8px;
    opacity: 1;
    padding: 32px 16px;
}
.contactSupportButton{

    border-radius: 8px;
    margin-left: 24px;
}
.helpingText{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #2E2E2E;
    margin-left: 8px;
}
.contactSupportContainer{

    margin-top: 16px;
}
.helpingTextContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.advanceFilterTagsContainer{
    display: none;
    justify-content: left;
    align-items: center;
    max-width: 320px;
    overflow: hidden;
    margin: 0px;
}



/* .redTag{
    color: #EF5555;
    background: rgba(239, 85, 85, 0.08);
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px;
    border-color: #EF5555;
    min-width: max-content;
}
.redTag:hover{ 
    background-color: #EF5555;
    border-color: #EF5555;
}
.redTag.focus{

}
.redTag.active{
    background-color: #EF5555;
    border-color: #EF5555;
} */

.blueTag{
    min-width: max-content;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px;
    font-size: 12px;
}

.filterTagScrollButton{
    display: none !important;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 2px 0px;
    margin: 0px 8px;
}




@media (min-width: 576px){
    .sac-container, .scoreContainer{
        max-width: 100%;
    }
}
@media (min-width: 768px){
    .sac-container, .scoreContainer{
        max-width: 100%;
    }
}
@media (min-width: 992px){
    .sac-container, .scoreContainer{
        max-width: 100%;
    }
}
@media (min-width: 1200px){
    .sac-container, .scoreContainer{
        max-width: 100%;
    }
}