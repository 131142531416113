.link{
    margin-left:35px;
    color:#297af7;
    text-decoration: none !important;
    font-size:17px;
    display: inline-block;
    cursor:pointer;
  }

  .headerr {
    padding: 10px 16px;
    height:64px;
    color: #f1f1f1;
    background-color:#2E2E2E;
    display:flex;
    justify-content:center;
    z-index:2;
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%
  }
  .header-inner{
    display:flex;
    width:100%;
    height:100%;
    align-items:center;
  }
  .logo-text{
    margin-left:25px 0px 25px 6.29px;
    font-weight: bold;
    font-size:20px;
  }
  .logo{
    margin:16px 0px 16px 32px;
  }