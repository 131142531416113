.all-jobs-content{
    display:flex;
    flex-direction:column;
    margin:50px;
    margin-top:60px;
    min-height:500px;
    border-top: 1px solid #E8E8E8;
    max-width:100%;
    word-break: 'break-word'
  }
  .jobs-table{
  }
  table.jobs-table> thead> tr>th{
    top: 3.95rem;
  }
  table.jobs-table> thead> tr>th>span{
    float:right;
  }
  table.jobs-table> tbody> tr>td.apply-width{
    width: 120px
  }
  table.jobs-table> tbody> tr>td>p,td.apply-width>span{
    float:right;
  }
  