.jd-container{
    /* margin-top: 80px; */
}

.jd-container > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
}

.jd-container > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 40px;
}

.jd-content {
    background-color: white;
    border-radius: 8px;
    /* border: 1px solid #E6E9ED; */
    margin-bottom: 0px 0px 36px 0px;
}
.jd-headerContainer{
    background: #F8F9FA;
    height: 100%;
    width: 100%;
    padding: 74px 0px 0px 0px;
}
.jd-heading{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: #2E2E2E;
    margin-bottom: 8px;
    padding-left: 20%;

}
.jd-message{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 40px;
    padding-left: 20%;
}
.jd-bottomBorder{
    width: 100%;
    margin: 0px;
    padding: 0px;
}