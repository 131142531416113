.logs-content{
    display:flex;
    flex-direction:column;
    margin:50px;
    margin-top:125px;
    min-height:500px;
    border-top: 1px solid #E8E8E8;
    max-width:100%
  }
  .logs-table{

  }
  table.logs-table> thead> tr>th{
    top: 8rem;
  }
  /* table.jobs-table> thead> tr>th>span{
    float:right;
  }
  table.jobs-table> tbody> tr>td.apply-width{
    width: 120px

  }
  table.jobs-table> tbody> tr>td,td.apply-width>span{
    float:right;
  }
   */