.autosuggest-suggestions-container {

    /* border-radius: 4px; */
    background-color: white;
    width: 418px;

    position: absolute;


}

.autosuggest-suggestions-container > ul{
    border: 1px solid #E6E9ED;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 0;
    max-height: 268px;
    overflow: auto;
}

.suggestion-item {

    height: 42px;
    line-height: 12px;
    cursor: pointer;
    padding: 16px;
}

.suggestion-item:hover{
    background-color: rgba(41, 122, 247, 0.05);
}