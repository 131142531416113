thead th {
    position: sticky;
    position: -webkit-sticky;
    background-color: white;
 
  }
  tbody{
  z-index:-5;
  }
  .spinner{
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  body{
    font-family: inter;
  }
  .thead-analytics{
    top: 10.2rem;
  }

  .thead-analytics>th{
    top: 14rem;
  }
  .thead-logs{
    top: 14rem;
  }
  .activity-types{
    position: -webkit-sticky;
    position:fixed;
    top: 4rem;
    height:65px;
    width:100%;
    background-color:white;
    display:flex;
    align-items:center;
    z-index:1;
    padding-top:20px
  }
  .score-and-connect
  {
    width:150px;
    float:right;
  }
  .date-filter-set{
    width:100px;
  }
  .for-sort-item{
    position:absolute;
    font-size: 15px;
    z-index:1;
  }
  .for-analytics-sorting{
    
  }
  .date-filters{
    position: -webkit-sticky;
    position:fixed;
    top: 4rem;
    height:104px;
    width:100%;
    background-color:white;
    display:flex;
    align-items:center;
    z-index:3;
  }
  .date-filter-inner{
    display:flex;
    justify-content: space-between;
    height:100%;
    min-width:100%;
    border-bottom: 1px solid #E8E8E8;
    align-items:center;
    padding-right: 64px;
  }
  .date-filter-title{
    margin-left:50px;
    margin-top:10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    justify-self:center;
    min-width: 150px;
  }
  .end-date-text{
    margin-left:20px;
    margin-top:10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    justify-self:center;
  }
  .log-form{
    width:500px;
  }
  .Button{
    background-color:#297af7;
  }
  .login-form{
    margin-left:200px;
    margin-right:200px;
    display:flex;
    justify-content:center;
  }
  .logs-table{

   
  }
  .activity-types{
    top:4rem
  }
  .logs-contaioner{
    width:100%;
    padding:50px;
    z-index:-2 inherit;
  }
  .content{
    display:flex;
    flex-direction:column;
    margin:50px;
    margin-top:160px;
    min-height:500px;
    border-top: 1px solid #E8E8E8;
    max-width:100%
  }
  .analytics-content{
    margin:50px;
    margin-top: 120px;
    min-height:500px;
    z-index:-3 inherit;
  }
  .analytics-content-container{
    margin-top:160px;
  }
  .jobs{
    display:flex;
    flex-wrap: wrap;
    margin-top:20px;
  }
  .analytics-column-data-flow{
    float:right;
  }
  .line{
    min-height:20px;
    margin-top:120px;
    border-top: 1px solid #E8E8E8;
  }
  .headerr {
    padding: 10px 16px;
    height:64px;
    color: #f1f1f1;
    background-color:#2E2E2E;
    display:flex;
    justify-content:center;
    z-index:5;
  }
  a {
    color: #007bff;
    text-decoration: none !important;
}
  .sticky {
    position: fixed;
    top: 0;
    width: 100%
  }
  .job-card{
    width:400px;
    height:300px;
    border: 1px solid black;
    margin:20px;
    padding:25px;
  }
  .header-inner{
    display:flex;
    width:100%;
    height:100%;
    align-items:center;
  }
  .logo-text{
    margin-left:25px 0px 25px 6.29px;
    font-weight: bold;
    font-size:20px;
  }
  .logo{
    margin:16px 0px 16px 32px;
  }
  .invalid-login-error{
    color:#FF0000;
  }
  
  .logout-dropdown{
  background-color:transparent;
  color:transparent;
  box-shadow:transparent
  }
  .logoutButton{
    padding:10px;
    margin-top:100px;
    margin-left:-100px;
    z-index:2;
    cursor:pointer;
  }
  
  .logout-dropdown:hover:focus{
    background-color:#2E2E2E;
    border-color:#2E2E2E;
    box-shadow:0
    }
  
  .user-logout-container{
    position: 'absolute';
    right: 0px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left: auto;
    margin-right:32px;
    min-width:20px;
    
  }
  .logout-icon{
   margin-left:10px;
    cursor:pointer;
  }
  .logout-z-index{
    position:absolute;
    z-index:2;
    
    cursor:pointer;
  }
  .username{
    margin-left:10px;
    margin-top:12px;
    cursor:pointer;
  }
  .link{
    margin-left:35px;
    color:#297af7;
    text-decoration: none;
    font-size:17px;
    display: inline-block;
    cursor:pointer;
  }
  .start-date-text{
  box-sizing: border-box;
  width:10%;
  }
  .apply-filter-button{
    margin-left:28px;
  }
  .reset-filter-form{
    margin-left:5px;
  }
  .analytics-sorting-dropdown{
    margin-left:20px;
  }