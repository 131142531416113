.svg-active {}

.svg-active > svg > path {
    stroke: #297AF7;
}

.svg-inactive {}

.svg-inactive > svg > path {
    stroke: #A7ABB0
}