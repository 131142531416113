.add-candidate-container{
    display:flex;
    flex-direction:column;
    align-items:center
}
.add-candidate-container-form{
    width:40%;
    margin-top:150px;
    justify-self:center
}
.add-candidate-submit-button{
margin-left:50px;
margin-top:20px;
}
.form-fields{
margin-top:50px;
}
.add-candidate-title{
margin-left:70px;
}
.organizations-content{
    display:flex;
    flex-direction:column;
    margin:50px;
    margin-top:125px;
    min-height:500px;
    border-top: 1px solid #E8E8E8;
    max-width:100%
  }
  .organizations-table{

  }
  table.organizations-table> thead> tr>th{
    top: 0;
  }
  /* table.jobs-table> thead> tr>th>span{
    float:right;
  }
  table.jobs-table> tbody> tr>td.apply-width{
    width: 120px

  }
  table.jobs-table> tbody> tr>td,td.apply-width>span{
    float:right;
  }
   */